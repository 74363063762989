<!-- OCRPage.vue -->
<template>
    <div class="ocr-page">
        <el-row :gutter="1" class="full-height" style="flex:1">
            <el-col :span="10"
                style="padding: 0px 24px;
                background-color: #F5F5F5;
                border-right: 1px solid rgba(55, 53, 47, 0.05);display: flex;justify-content: center;height: 100%;flex-direction: column;">
                <div style="flex: 1;">
                    <ImageUploader :imageSrc="imageSrc" :isInWebView="isInWebView" @image-upload="handleImageUpload"
                        @open-settings="openSettings">
                    </ImageUploader>
                </div>
                <el-divider class="line50"></el-divider>
                <div class="bottom">
                    <el-upload
                        class="upload-btn"
                        action="#"
                        :show-file-list="false"
                        :on-change="handleImageUpload"
                        accept="image/*"
                    >
                        <el-button class="btn" icon="el-icon-upload" round size="small">上传</el-button>
                    </el-upload>
                    <el-button class="btn" icon="el-icon-set-up" round size="small"
                        @click="openSettings">Preferences</el-button>
                    <el-button class="btn" icon="el-icon-switch-button" round size="small"
                        @click="confirmQuit">Exit</el-button>
                </div>
            </el-col>
            <el-col :span="14"
                style="padding: 0px 24px;margin-bottom: 0px;height: 100%;display: flex;flex-direction: column;background-color: white;">
                <div
                    style="min-height: 64px; border-bottom: 1px solid rgba(55, 53, 47, 0.15); display: flex; align-items: flex-end; justify-content: center; overflow: hidden; flex-wrap: wrap;">
                    <div class="tab" :class="{ active: activeTab === 'fast' }" @click="clickTab('fast')"
                        :disabled="isProcessing">
                        <div
                            style="width: 24px; height: 24px;display: flex;align-items: center;justify-content: center;">
                            <PhCubeFocusFill style="width: 24px; height: 24px;"></PhCubeFocusFill>
                        </div>
                        Fast
                    </div>
                    <div class="tab" :class="{ active: activeTab === 'smart' }" @click="clickTab('smart')"
                        :disabled="isProcessing">
                        <div
                            style="width: 24px; height: 24px;display: flex;align-items: center;justify-content: center;">
                            <EpMagicStick style="width: 24px; height: 24px;"></EpMagicStick>
                        </div>
                        Smart
                    </div>
                    <div class="tab" :class="{ active: activeTab === 'table' }" @click="clickTab('table')"
                        :disabled="isProcessing">
                        <div
                            style="width: 24px; height: 24px;display: flex;align-items: center;justify-content: center;">
                            <TdesignTable style="width: 20px;height: 20px;"></TdesignTable>
                        </div>
                        Table
                    </div>
                    <div class="tab" :class="{ active: activeTab === 'function' }" @click="clickTab('function')"
                        :disabled="isProcessing">
                        <div
                            style="width: 24px; height: 24px;display: flex;align-items: center;justify-content: center;">
                            <GravityUiFunction style="width: 24px;height: 24px;"></GravityUiFunction>
                        </div>
                        Function
                    </div>
                    <div class="tab" :class="{ active: activeTab === 'block' }" @click="clickTab('block')"
                        :disabled="isProcessing">
                        <div style="width: 24px; height: 24;display: flex;align-items: center;justify-content: center;">
                            <MingcuteBlockquoteLine style="width: 24px;height: 24px;"></MingcuteBlockquoteLine>
                        </div>
                        Block
                    </div>
                </div>
                <div style="height: calc(100% - 80px);">
                    <OCRAppleContainer v-if="activeTab === 'fast'" :ocr-result="ocrResult" :ocr-error="ocrError">
                    </OCRAppleContainer>
                    <OCRAIContainer v-else :aiResult="aiResult[activeTab]" :is-processing="isProcessing"
                        :aiResultError="aiResultError" @refresh-result="refreshResult"
                        :aiResultPromptNO="aiResultPromptNO" @copy-result="copyResult">
                    </OCRAIContainer>
                </div>

            </el-col>
        </el-row>
    </div>
</template>

<script>
import ImageUploader from './ImageUploader.vue';
import OCRAppleContainer from './OCRAppleContainer.vue';
import OCRAIContainer from './OCRAIContainer.vue';
import axios from 'axios';

import PhCubeFocusFill from './tabs/PhCubeFocusFill.vue';
import EpMagicStick from './tabs/EpMagicStick.vue';
import TdesignTable from './tabs/TdesignTable.vue';
import GravityUiFunction from './tabs/GravityUiFunction.vue';
import MingcuteBlockquoteLine from './tabs/MingcuteBlockquoteLine.vue';

export default {
    name: 'OCRPage',
    components: {
        ImageUploader,
        OCRAppleContainer,
        OCRAIContainer,
        PhCubeFocusFill,
        EpMagicStick,
        TdesignTable,
        GravityUiFunction,
        MingcuteBlockquoteLine
    },
    data() {
        return {
            imageSrc: '',
            ocrResult: ``,
            ocrTextBlocks: ``,
            ocrError: false,
            isProcessing: false, // 新增加载状态
            aiResult: {},
            aiResultError: '', // 新增错误提醒
            aiResultPromptNO: '',    //AI使用了哪个prompt
            activeTab: 'fast', // 默认激活 'fast' tab
            abortController: null, // 新增 abortController 属性,
            deviceId: '',
            version: '',
        };
    },
    computed: {
        isInWebView() {
            // 检查是否在WebView环境中
            // 后检查URL中的chrom参数
            let inWebView = true;
            const chromParam = this.getUrlParam('chrom');
            if (chromParam !== null) {
                // 如果URL中存在chrom参数，根据参数值调整inWebView
                inWebView = false;
            }
            return inWebView;
        },
        getVersion() {
            if (this.version) {
                return `NextOCR ${this.version}`;
            }
            return '';
        },
    },
    methods: {
        getUrlParam(paramName) {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get(paramName);
        },
        initAiResultMap() {
            this.aiResult = {
                smart: '',
                table: '',
                function: '',
                block: ''
            };
        },
        setOCRImage(base64Image) {
            // console.log('vue-message setOCRImage from :', base64Image);
            // 确保base64Image包含所需的前缀，如果没有则添加
            if (!base64Image.startsWith('data:image/png;base64,')) {
                this.imageSrc = 'data:image/png;base64,' + base64Image;
                this.aiResult = '';
            }
            this.activeTab = 'fast';
            this.initAiResultMap();
            this.isProcessing = false;
            // 如果有正在进行的请求,中断它
            if (this.abortController) {
                this.abortController.abort();
                this.abortController = null;
            }
        },
        setOCRResult(base64Result, base64TextBlocks) {
            this.ocrResult = decodeURIComponent(escape(window.atob(base64Result)));
            this.ocrError = this.ocrResult.includes('失败');
            this.activeTab = 'fast';
            this.initAiResultMap();
            this.isProcessing = false;
            // 解码并解析文本块数据
            const textBlocksData = decodeURIComponent(escape(window.atob(base64TextBlocks)));
            this.ocrTextBlocks = textBlocksData;
            // console.log('vue-message setOCRResult:', this.ocrResult, this.ocrTextBlocks);
        },
        feedBack(event) {
            console.log('vue-message FeedBack:', this.aiResultPromptNO, event);
            if (event) {
                const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
                axios.post(apiEndpoint + '/feedback', {
                    prompt_number: this.aiResultPromptNO,
                    event: event,
                    device_id: this.deviceId,
                })
                    .then(response => {
                        console.log('Feedback recorded:', response.data);
                    })
                    .catch(error => {
                        console.error('Error recording feedback:', error);
                    });
            }
        },
        copyResult() {
            // console.log('vue-message copyResult:', this.aiResult);
            this.feedBack('copy');
        },
        refreshResult() {
            this.feedBack('refresh');
            this.processImage(this.imageSrc, true, this.activeTab);
        },
        async addBlackBackground(imageSrc, padding) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = imageSrc;
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');

                    // 设置 Canvas 的尺寸，包括黑色背景
                    canvas.width = img.width + padding * 2;
                    canvas.height = img.height + padding * 2;

                    // 绘制黑色背景
                    ctx.fillStyle = 'black';
                    ctx.fillRect(0, 0, canvas.width, canvas.height);

                    // 将图片绘制到 Canvas 上
                    ctx.drawImage(img, padding, padding);

                    // 将处理后的图片数据从 Canvas 中提取出来，保留原始的图片格式
                    const processedImageSrc = canvas.toDataURL(img.src.substring(img.src.indexOf(':') + 1, img.src.indexOf(';')));
                    resolve(processedImageSrc);
                };
                img.onerror = (error) => {
                    reject(error);
                };
            });
        },
        /* eslint-disable */
        async processImage(imageSrc, fresh = false, tab = 'smart') {
            if (this.isProcessing) {
                console.log('Image processing is already in progress.');
                return;
            }

            const processedImageSrc = await this.addBlackBackground(imageSrc, 20);

            const prefix = processedImageSrc.substring(0, processedImageSrc.indexOf(';base64,'));
            const base64Image = processedImageSrc.replace(/^data:image\/\w+;base64,/, '');
            const imageType = this.getFileTypeByPrefix(prefix);

            this.isProcessing = true;
            this.aiResult[tab] = '';
            this.aiResultError = '';
            this.aiResultPromptNO = '';
            this.abortController = new AbortController();

            try {
                // 修改这里的 URL 以指向本地服务
                const response = await fetch(`${process.env.VUE_APP_API_ENDPOINT}/process-image`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        image_base64: base64Image,
                        image_media_type: imageType,
                        text_source: this.ocrTextBlocks ? this.ocrTextBlocks : this.ocrResult,
                        fresh: fresh,
                        device_id: this.deviceId,
                        tab: tab
                    }),
                    signal: this.abortController.signal,
                });

                if (!response.ok) {
                    throw new Error(`HTTP error ${response.status}: ${response.statusText}`);
                }

                const data = await response.json();

                if (typeof data.result === 'string') {
                    // 如果 result 是字符串，直接使用
                    this.aiResult[tab] = data.result;
                } else if (Array.isArray(data.result)) {
                    // 如果 result 是数组，保留原来的处理逻辑
                    this.aiResult[tab] = data.result?.map(item => item.text)?.join('\n');
                } else {
                    throw new Error('Invalid data format received from server.');
                }

                // 设置prompt_number
                this.aiResultPromptNO = data.prompt_number;

            } catch (error) {
                if (error.name === 'AbortError') {
                    console.log('Request aborted');
                } else {
                    console.error('Error processing image:', error);
                    this.aiResultError = `Error processing image: ${error.message}`;
                }
            } finally {
                this.isProcessing = false;
                this.abortController = null;
            }
        },
        getFileTypeByPrefix(prefix) {
            // 通过前缀判断文件类型
            const typeMapping = {
                'data:image/jpeg': 'image/jpeg',
                'data:image/png': 'image/png',
                'data:image/gif': 'image/gif',
                'data:image/bmp': 'image/bmp',
                // 根据需要添加更多的映射
            };
            return typeMapping[prefix] || 'application/octet-stream'; // 默认返回类型，用于未知数据类型
        },
        clickTab(tab) {
            if (!this.isProcessing && this.activeTab !== tab) {
                this.activeTab = tab;
                if (this.activeTab !== 'fast' && this.aiResult[tab]?.length === 0 && this.imageSrc.length > 0) {
                    this.processImage(this.imageSrc, false, tab);
                }
            }
        },
        handleImageUpload(file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                this.imageSrc = e.target.result;
                this.initAiResultMap();
                this.activeTab = 'fast';
            };
            reader.readAsDataURL(file.raw);
        },
        // 新增一个方法来根据文件名获取MIME类型
        getFileType(fileName) {
            const extension = fileName.split('.').pop().toLowerCase();
            const mapping = {
                'jpg': 'image/jpeg',
                'jpeg': 'image/jpeg',
                'png': 'image/png',
                'gif': 'image/gif',
                'bmp': 'image/bmp',
                // 可以根据需要添加更多的文件类型
            };
            return mapping[extension] || 'application/octet-stream'; // 默认返回类型，用于未知文件类型
        },

        customUploadRequest(options) {
            // 自定义上传处理函数
            const file = options.file;
            this.handleImageUpload(file);
        },
        getSeflPrompt() {
            return `请根据提供的图片版式，使用Markdown语法精确地格式化以下原始数据，包括正文，表格，公式等。不做任何的解释和创造。你的任务是让markdown的结果尽量和图片的版式一致。原始数据如下：\n${this.ocrResult}`;
        },
        setTestAIResult() {
            this.ocrResult= ``;
            this.aiResult.smart = ``;
            this.aiResult.table = `| 项目 | 价格 |\n| ---- | ---- |\n| 苹果 | 5元 |\n| 香蕉 | 3元 |`;
            this.aiResult.function = `$$\n\\int_0^1 x^2 dx = \\frac{1}{3}\n$$`;
            this.aiResult.block = `> 这是一个测试的AI结果。`;
        },
        convertSymbols(text) {
            if (!text)
                return text;
            const halfWidthSymbols = `,.!?:;()[]{}""\'\'`;
            const fullWidthSymbols = `，。！？：；（）【】「」""''`;

            let convertedText = '';

            for (let i = 0; i < text.length; i++) {
                const char = text[i];
                const index = halfWidthSymbols.indexOf(char);

                if (index !== -1 && this.isChinese(text[i - 1])) {
                    convertedText += fullWidthSymbols[index];
                } else {
                    convertedText += char;
                }
            }

            return convertedText;
        },

        isChinese(char) {
            const chineseRegex = /[\u4e00-\u9fff]/;
            return chineseRegex.test(char);
        },
        openSettings() {
            // 调用 Objective-C 的打开设置函数
            console.log('vue-message openSettings');
            try {
                window.webkit.messageHandlers.openSettings.postMessage(null);
            } catch (e) {
                console.log('vue-message openSettings exception:', e);
            }
        },
        confirmQuit() {
            // 弹出确认对话框
            this.$confirm('Are you sure you want to quit the application?', 'Confirm', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                // 用户点击确定，调用 Objective-C 的退出函数
                console.log('vue-message confirmQuit');
                window.webkit.messageHandlers.quitApplication.postMessage(null);
            }).catch(() => {
                // 用户点击取消，不做任何操作
            });
        }
    },
    mounted() {
        window.OCJS_updateOcrResult = this.setOCRResult;
        window.OCJS_updateOcrImage = this.setOCRImage;
        this.deviceId = this.getUrlParam('deviceid');
        this.version = this.getUrlParam('version');
        console.log('vue-message OCJS_updateOcrResult and OCJS_updateOcrImage are set.');
        this.initAiResultMap();
        // this.setTestAIResult();
    },
    beforeUnmount() {
        delete window.OCJS_updateOcrResult;
        delete window.OCJS_updateOcrImage;
    },
};
</script>

<style scoped>
.ocr-page {
    display: flex;
    flex-direction: row;
    height: 100vh;
    overflow: hidden;
    /* background-image: linear-gradient(to top right, #502974, #20B680); */
    /* 确保 .ocr-page 撑满整个屏幕的高度 */
}

.bottom {
    height: 64px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.left i:hover {
    font-size: 24px;
    font-weight: bold;
}

.full-height {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.full-height {
    height: 100%;
}

.tab {
    display: inline-block;
    padding: 8px 12px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(55, 53, 47);
    border-radius: 8px;
    font-size: 14px;
    min-width: 60px;
    /* 添加其他样式 */
}

.tab:hover {
    transform: scale(1.05);
    /* 当鼠标悬停时放大5% */
    transition: transform 0.3s ease;
    /* 添加平滑过渡效果 */
    transform-origin: bottom center;
    /* 添加这一行 */
}


.tab.active {
    color: #FC4D5C;
    transform: scale(1.1);
    transform-origin: bottom center;
    /* 添加这一行 */
}

.tab[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
}

.btn {
    background-color: transparent;
    color: rgb(55, 53, 47);
    font-size: 14px;
    margin-right: 12px;
}

.btn:hover {
    border: 1px solid rgb(55, 53, 47, 0.4);
    transform: scale(1.05);
    /* 当鼠标悬停时放大5% */
    transition: transform 0.3s ease;
    /* 添加平滑过渡效果 */
}

::v-deep .btn i {
    font-size: 13px;
}

::v-deep .el-divider--horizontal {
    margin: 4px 0px;
}

.version-container {
    position: fixed;
    bottom: 20px;
    left: 20%;
    transform: translateX(-50%);
    z-index: 1;
}

.version {
    color: rgb(255, 255, 255, 0.5);
    font-size: 14px;
    margin-bottom: -12px;
}

.upload-btn {
    display: inline-block;
    margin-right: 12px;
}

.upload-btn ::v-deep .el-upload {
    display: block;
}
</style>