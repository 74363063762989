<template>
    <div class="ocr-ai-container">
        <div class="scrollable-content" style="flex: 1;">
            <p v-if="isProcessing">
                <i class="el-icon-loading"></i> 识别中...
            </p>
            <div class="content" v-else>
                <div v-html="renderedResult" class="markdown-body"></div>
            </div>
            <p v-if="aiResultError">
                <i class="el-icon-warning-outline"></i> {{ aiResultError }}
            </p>
        </div>
        <el-divider class="line50"></el-divider>
        <div style="display: flex;justify-content: center;width: 100%; height: 64px;align-items: center">
            <el-button class="btn" icon="el-icon-copy-document" round size="small" @click="copyResult">Copy</el-button>
            <el-button class="btn" icon="el-icon-refresh-left" round size="small" @click="refreshResult">Try
                Again</el-button>
        </div>
    </div>
</template>

<script>
import MarkdownIt from 'markdown-it';
import tm from 'markdown-it-texmath';
import 'katex/dist/katex.min.css'; // 导入 KaTeX CSS
export default {
    props: ['aiResult', 'isProcessing', 'aiResultError'],
    computed: {
        renderedResult() {
            // console.log('aiResult', this.aiResult)
            return this.markdownToHtml(typeof this.aiResult === 'string' ? this.aiResult : '');
        }
    },
    methods: {
        markdownToHtml(markdown) {
            const md = new MarkdownIt({
                html: true,
                breaks: true,
                typographer: true
            }).use(tm, {
                engine: require('katex'),
                delimiters: 'dollars',
                katexOptions: { 
                    throwOnError: false,
                    output: 'html',
                    macros: {
                        "\\Bigg": "\\Huge",  // 添加对 \Bigg 的支持
                    },
                    strict: false  // 添加宽松模式
                }
            });
            
            return md.render(markdown);
        },
        copyResult() {
            // if (this.aiResult) {
            this.$emit('copy-result');
            navigator.clipboard.writeText(this.aiResult).then(() => {
                this.$message.success('Markdown 原始内容已复制到剪贴板');
            }, (err) => {
                console.error('无法复制文本: ', err);
            });
            // }

        },
        refreshResult() {
            this.$emit('refresh-result');
        }
    }
}
</script>

<style scoped>
.ocr-ai-container {
    height: 100%;
    padding: 0px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
}

.ocr-ai-container p {
    color: rgb(55, 53, 47);
}

.content {
    color: rgb(55, 53, 47);
    line-height: 1.5em;
}

.btn {
    background-color: transparent;
    color: rgb(55, 53, 47);
    font-size: 14px;
}

.btn:hover {
    border: 1px solid rgb(55, 53, 47, 0.4);
    transform: scale(1.05);
    /* 当鼠标悬停时放大5% */
    transition: transform 0.3s ease;
    /* 添加平滑过渡效果 */
}

::v-deep .btn i {
    font-size: 13px;
}


::v-deep .el-divider--horizontal {
    margin: 4px 0px;
}

/* 添加数学公式相关样式 */
.markdown-body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
}

/* 调整公式显示样式 */
.katex-display {
    overflow-x: auto;
    overflow-y: hidden;
    padding: 1em 0;
    margin: 0.5em 0;
}

/* 处理长公式换行 */
.katex-display > .katex {
    white-space: normal;
}

/* 确保分段函数正确显示 */
.cases-l {
    margin-right: 0.2em;
}
</style>
